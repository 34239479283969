import {useAuth} from '../hooks/use-auth';
import React, {Dispatch, SetStateAction, createContext, useState} from 'react';
import {Container, Header, Tabs} from '@amzn/awsui-components-react';
import {Notification} from '../navigation/page-layout';
import {AnalyticsAPIforExcelsiorAnalytics} from '@amzn/f3-excelsior-analytics-api';
import ForecastView from './forecast-view';
import BaselineWeekView from './baseline-week-view';

type ForecastContextType = {
    forecast: {[cacheKey: string]: AnalyticsAPIforExcelsiorAnalytics.Types.GetForecastOutput} | undefined;
    setForecast: Dispatch<
        SetStateAction<{[cacheKey: string]: AnalyticsAPIforExcelsiorAnalytics.Types.GetForecastOutput} | undefined>
    >;
    aggregatedData: {[cacheKey: string]: AnalyticsAPIforExcelsiorAnalytics.Types.GetAggregatedRecordsOutput} | undefined;
    setAggregatedData: Dispatch<
        SetStateAction<{[cacheKey: string]: AnalyticsAPIforExcelsiorAnalytics.Types.GetAggregatedRecordsOutput} | undefined>
    >;
};

export const ForecastContext = createContext<ForecastContextType>({
    forecast: undefined,
    setForecast: () => {},
    aggregatedData: undefined,
    setAggregatedData: () => {},
});

export const ForecastProvider = ({children}) => {
    const [forecast, setForecast] = useState<{[cacheKey: string]: AnalyticsAPIforExcelsiorAnalytics.Types.GetForecastOutput}>();
    const [aggregatedData, setAggregatedData] = useState<{
        [cacheKey: string]: AnalyticsAPIforExcelsiorAnalytics.Types.GetAggregatedRecordsOutput;
    }>();

    return (
        <ForecastContext.Provider value={{forecast, setForecast, aggregatedData, setAggregatedData}}>
            {children}
        </ForecastContext.Provider>
    );
};

export default function ForecastBuilder(props: {pushNotification: (notification: Notification) => void}) {
    const auth = useAuth();
    const configurationData = auth.authInformation!.current!.configurationData!;

    if (!configurationData.forecastBuilderEnabledOnPortal) {
        return <React.Fragment></React.Fragment>;
    }

    const forecastBuilderTabs = [
        {
            label: 'Weekly Forecast',
            id: 'weekly',
            content: <ForecastView timeGranularity="weekly" pushNotification={props.pushNotification}></ForecastView>,
        },
        {
            label: 'Daily Forecast',
            id: 'daily',
            content: <ForecastView timeGranularity="daily" pushNotification={props.pushNotification}></ForecastView>,
        },
    ];

    if (configurationData.baselineWeeksTabEnabledOnForecastBuilder) {
        forecastBuilderTabs.push({
            label: 'Baseline Weeks',
            id: 'baselineWeeks',
            content: <BaselineWeekView pushNotification={props.pushNotification}></BaselineWeekView>,
        });
    }

    return (
        <React.Fragment>
            <ForecastProvider>
                <Container header={<Header variant="h2">Forecast Builder</Header>}>
                    <Tabs tabs={forecastBuilderTabs} />
                </Container>
            </ForecastProvider>
        </React.Fragment>
    );
}
