import React from 'react';
import {Redirect, Route} from 'react-router';
import Home from './home';
import Support from './support';
import Insights from './insights';
import Unauthorized from './common/unauthorized';
import BusinessSelection from './business-selection/business-selection';
import {withPageLayout} from './navigation/page-layout';
import ProtectedBusinessSpecificRoute from './navigation/protected-business-specific-route';
import {useAuth} from './hooks/use-auth';
import PortalLoader from './common/PortalLoader';
import ViewOperationalSchedule from './orchestrator/view-operational-schedule';
import ViewForecastHistory from './history/view-forecast-history';
import ViewOperationalDashboard from './operational-dashboard/view-operational-dashboard';
import {ViewForecast} from './forecast/view-forecast';
import {ViewAccuracyDashboard} from './accuracy-dashboard/view-accuracy-dashboard';
import {InputSignal, Overrides} from './bulk-override';
import {ViewSchedule} from './orchestrator/view-schedule';
import {EditSchedule} from './orchestrator/edit-schedule';
import {MerchantAutomation} from './merchant-automation';
import ViewInsights from './analytics/view-insights';
import ForecastBuilder from './forecast-builder';

interface CoreAppProps {
    home: React.ComponentType<any>;
    support: React.ComponentType<any>;
    insights: React.ComponentType<any>;
    unauthorized: React.ComponentType<any>;
    businessSelection: React.ComponentType<any>;
    viewOperationalSchedule: React.ComponentType<any>;
    viewSchedule: React.ComponentType<any>;
    editSchedule: React.ComponentType<any>;
    viewForecast: React.ComponentType<any>;
    viewAccuracyDashboard: React.ComponentType<any>;
    bulkOverride: React.ComponentType<any>;
    inputSignal: React.ComponentType<any>;
    viewForecastHistory: React.ComponentType<any>;
    viewOperationalDashboard: React.ComponentType<any>;
    manageMerchants: React.ComponentType<any>;
    viewExplainability: React.ComponentType<any>;
    buildForecast: React.ComponentType<any>;
}

export default function AppV2() {
    return (
        <CoreApp
            home={withPageLayout(Home)}
            support={withPageLayout(Support)}
            insights={withPageLayout(Insights)}
            unauthorized={withPageLayout(Unauthorized)}
            businessSelection={withPageLayout(BusinessSelection)}
            viewOperationalSchedule={withPageLayout(ViewOperationalSchedule)}
            viewSchedule={withPageLayout(ViewSchedule)}
            editSchedule={withPageLayout(EditSchedule)}
            viewForecast={withPageLayout(ViewForecast)}
            viewAccuracyDashboard={withPageLayout(ViewAccuracyDashboard)}
            viewExplainability={withPageLayout(ViewInsights)}
            bulkOverride={withPageLayout(Overrides)}
            inputSignal={withPageLayout(InputSignal)}
            viewForecastHistory={withPageLayout(ViewForecastHistory)}
            viewOperationalDashboard={withPageLayout(ViewOperationalDashboard)}
            manageMerchants={withPageLayout(MerchantAutomation)}
            buildForecast={withPageLayout(ForecastBuilder)}
        />
    );
}

export function CoreApp(props: CoreAppProps) {
    const auth = useAuth();

    if (!auth.isAuthenticated) {
        // render loader while authentication with cognito is still happening
        return <PortalLoader />;
    }

    return (
        <React.Fragment>
            <Route
                exact
                path="/"
                render={() => {
                    return !auth.authInformation?.current ? <Redirect to="/business-selection" /> : <Redirect to="/forecast" />;
                }}
            />
            <Route exact path="/home" component={props.home} />
            <Route exact path="/support" component={props.support} />
            <Route exact path="/unauthorized" component={props.unauthorized} />
            <ProtectedBusinessSpecificRoute
                isAuthorized={true}
                exact
                path="/business-selection"
                component={props.businessSelection}
            />
            <ProtectedBusinessSpecificRoute
                isAuthorized={auth.authInformation?.current?.permissions.canViewForecasts || false}
                exact
                path="/forecast"
                component={props.viewForecast}
            />
            <ProtectedBusinessSpecificRoute
                isAuthorized={auth.authInformation?.current?.permissions.canViewForecasts || false}
                exact
                path="/forecast-builder"
                component={props.buildForecast}
            />
            <ProtectedBusinessSpecificRoute
                isAuthorized={auth.authInformation?.current?.permissions.canViewAccuracyDashboard || false}
                exact
                path="/accuracy-dashboard"
                component={props.viewAccuracyDashboard}
            />
            <ProtectedBusinessSpecificRoute
                isAuthorized={auth.authInformation?.current?.permissions.canViewExplainability || false}
                exact
                path="/explain"
                component={props.viewExplainability}
            />
            <ProtectedBusinessSpecificRoute
                isAuthorized={auth.authInformation?.current?.permissions.canManageForecastWorkflows || false}
                exact
                path="/operational-schedule"
                component={props.viewOperationalSchedule}
            />
            <ProtectedBusinessSpecificRoute
                isAuthorized={auth.authInformation?.current?.permissions.canManageForecastWorkflows || false}
                exact
                path="/schedule"
                component={props.viewSchedule}
            />
            <ProtectedBusinessSpecificRoute
                isAuthorized={auth.authInformation?.current?.permissions.canManageForecastWorkflows || false}
                exact
                path="/schedule/edit"
                component={props.editSchedule}
            />
            <ProtectedBusinessSpecificRoute
                isAuthorized={auth.authInformation?.current?.permissions.canUploadBulkOverrides || false}
                exact
                path="/overrides"
                component={props.bulkOverride}
            />
            <ProtectedBusinessSpecificRoute
                isAuthorized={auth.authInformation?.current?.permissions.canUploadMarketingInputs || false}
                exact
                path="/signals"
                component={props.inputSignal}
            />
            <ProtectedBusinessSpecificRoute
                isAuthorized={auth.authInformation?.current?.permissions.canViewForecasts || false}
                exact
                path="/history"
                component={props.viewForecastHistory}
            />
            <Route
                exact
                path="/stores"
                component={
                    auth.authInformation?.globalScopes &&
                    (auth.authInformation?.globalScopes['merchants']?.split(',').includes('view') ?? false)
                        ? props.manageMerchants
                        : props.unauthorized
                }
            />
            <Route
                exact
                path="/operational-dashboard"
                component={
                    auth.authInformation?.globalScopes &&
                    (auth.authInformation?.globalScopes['operationalDashboard']?.split(',').includes('view') ?? false)
                        ? props.viewOperationalDashboard
                        : props.unauthorized
                }
            />

            <Route
                exact
                path="/insights"
                component={
                    auth.authInformation?.globalScopes &&
                    (auth.authInformation?.globalScopes['oncallOperations']?.split(',').includes('manage') ?? false)
                        ? props.insights
                        : props.unauthorized
                }
            />
        </React.Fragment>
    );
}
