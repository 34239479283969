import React, {useEffect, useRef, useState, useCallback, memo} from 'react';
import {ServiceResource, useAuth} from '../hooks/use-auth';
import {useRouter} from '../hooks/use-router';
import {Notification} from '../navigation/page-layout';
import {translateErrorToReactNode} from '../common';
import {
    Container,
    ColumnLayout,
    SpaceBetween,
    Header,
    Spinner,
    Select,
    Tabs,
    FormField,
    Button,
    Form,
} from '@amzn/awsui-components-react';
import {useGetExplainableDataObjects, useGetMostRecentModelRunMetadata} from '../hooks/use-analytics-api';
import {AnalyticsAPIforExcelsiorAnalytics} from '@amzn/f3-excelsior-analytics-api';

export function LgbmExplainer(props: {pushNotification: (notification: Notification) => void}) {
    console.log(`loading LGBM explainer...`);

    const auth = useAuth();
    const router = useRouter();
    const initialized = useRef(false);
    const isLoading = useRef<boolean>(true);
    const [nodes, setNodes] = useState<string[]>([]);
    const [selectedNode, setSelectedNode] = useState<string>();
    const [metrics, setMetrics] = useState<string[]>([]);
    const [selectedMetric, setSelectedMetric] = useState<string>();

    const clientConfiguration = auth.authInformation!.getCurrentServiceEndpoint(ServiceResource.ExplainabilityView);

    const modelName = auth.authInformation?.current?.configurationData?.modelName ?? '';

    const defaultLogisticsType = 'delivery';

    function createErrorListener<T>(header: string) {
        return (e: any) => {
            props.pushNotification({
                type: 'error',
                content: translateErrorToReactNode(e),
                header,
            });
        };
    }

    const tenant = {
        business: auth.authInformation!.current!.businessId,
        country: auth.authInformation!.current!.country,
        flow: auth.authInformation!.current!.flow,
    };

    const [pollInterval, setPollInterval] = useState<any>(null);
    const [mostRecentModelRunMetadata, setMostRecentModelRunMetadata] = useState<
        AnalyticsAPIforExcelsiorAnalytics.Types.GetMostRecentModelRunMetadataOutput | undefined
    >(undefined);

    const {execute: executeGetMostRecentModelRunMetadata, value: getMostRecentModelRunMetadataResponse} =
        useGetMostRecentModelRunMetadata(clientConfiguration, createErrorListener('GetMostRecentModelRunMetadata failed'), [auth]);

    const {execute: executeGetExplainableDataObjects, value: getExplainabilityResponse} = useGetExplainableDataObjects(
        clientConfiguration,
        createErrorListener('RetrieveInsights failed'),
        [auth]
    );

    const clearPollingInterval = useCallback(() => {
        isLoading.current = false;
        if (pollInterval) {
            clearInterval(pollInterval);
            setPollInterval(null);
        }
    }, [pollInterval]);

    const startPolling = useCallback(() => {
        if (mostRecentModelRunMetadata) {
            isLoading.current = true;
            const executeAndPoll = () => {
                executeGetExplainableDataObjects({
                    ...tenant,
                    forecastType: 'sop',
                    filter: JSON.stringify({
                        modelName: modelName,
                        logisticsType: defaultLogisticsType,
                        metric: selectedMetric ?? 'units',
                        modelRunId: mostRecentModelRunMetadata.modelRunId,
                        forecastStartDate: mostRecentModelRunMetadata.forecastStartDate,
                        numberOfDays: 7,
                        numberOfDaysOffset: 7,
                        node: selectedNode,
                    }),
                });
            };

            executeAndPoll();

            // Set up polling
            const interval = setInterval(executeAndPoll, 10000); // 10 seconds
            setPollInterval(interval);
        }
    }, [mostRecentModelRunMetadata, selectedMetric, selectedNode]);

    const handleRefreshClick = () => {
        clearPollingInterval(); // Clear any existing polling
        startPolling(); // Start new polling
    };

    useEffect(() => {
        if (!initialized.current) {
            executeGetMostRecentModelRunMetadata({
                ...tenant,
                forecastType: 'sop',
                filter: JSON.stringify({modelName: modelName, logisticsType: defaultLogisticsType}),
            });

            initialized.current = true;
        }

        return () => {
            clearPollingInterval();
        };
    }, []);

    useEffect(() => {
        if (getMostRecentModelRunMetadataResponse) {
            setNodes(getMostRecentModelRunMetadataResponse.nodes ?? []);
            setSelectedNode(getMostRecentModelRunMetadataResponse.nodes ? getMostRecentModelRunMetadataResponse.nodes[0] : '');
            setMetrics(['units', 'orders']);
            setSelectedMetric('units');
            setMostRecentModelRunMetadata(getMostRecentModelRunMetadataResponse);
        }
    }, [getMostRecentModelRunMetadataResponse]);

    useEffect(() => {
        startPolling();

        return () => {
            clearPollingInterval();
        };
    }, [mostRecentModelRunMetadata]);

    useEffect(() => {
        if (getExplainabilityResponse?.status === 'COMPLETED') {
            // Stop polling when status is COMPLETED
            clearPollingInterval();
        }
    }, [getExplainabilityResponse, clearPollingInterval]);

    return (
        <React.Fragment>
            <Container
                header={
                    <Header
                        variant="h2"
                        description={
                            <ul>
                                <li>Forecast Start Date: {mostRecentModelRunMetadata?.forecastStartDate}</li>
                                <li>Model Run Id: {mostRecentModelRunMetadata?.modelRunId}</li>
                                <li>Forecast Run Date: {mostRecentModelRunMetadata?.snapshotDate}</li>
                            </ul>
                        }
                    >
                        Most Recent Forecast
                    </Header>
                }
            >
                {isLoading.current ? (
                    <Spinner></Spinner>
                ) : (
                    <Tabs
                        tabs={[
                            {
                                label: 'Summary Plots',
                                id: 'summary',
                                content: (
                                    <SpaceBetween size="s">
                                        <ColumnLayout columns={2}>
                                            {getExplainabilityResponse?.images
                                                ?.filter((image) => image.name === 'FeatureImportanceSummaryPlot')
                                                .map((image, index) => (
                                                    <div key={index}>
                                                        <Container
                                                            header={
                                                                <Header variant="h3" description="">
                                                                    {image.name}
                                                                </Header>
                                                            }
                                                        >
                                                            <img style={{width: '100%'}} src={image.imageUrl} />
                                                        </Container>
                                                    </div>
                                                ))}
                                        </ColumnLayout>
                                    </SpaceBetween>
                                ),
                            },
                            {
                                label: 'Waterfall Plots',
                                id: 'waterfall',
                                content: (
                                    <SpaceBetween size="m">
                                        <ColumnLayout columns={2}>
                                            <div>
                                                <Form
                                                    actions={
                                                        <SpaceBetween direction="horizontal" size="xs">
                                                            <Button formAction="none" variant="link" disabled>
                                                                Cancel
                                                            </Button>
                                                            <Button variant="primary" onClick={handleRefreshClick}>
                                                                Generate Insights
                                                            </Button>
                                                        </SpaceBetween>
                                                    }
                                                >
                                                    <Container
                                                        header={
                                                            <Header variant="h2">
                                                                Search individual nodes/stores for T+1 insights
                                                            </Header>
                                                        }
                                                    >
                                                        <SpaceBetween direction="vertical" size="l">
                                                            <FormField label="Node/store">
                                                                <Select
                                                                    selectedOption={{label: selectedNode, value: selectedNode}}
                                                                    onChange={({detail}) => {
                                                                        setSelectedNode(detail.selectedOption.value);
                                                                    }}
                                                                    options={nodes.map((item) => ({
                                                                        label: item,
                                                                        value: item,
                                                                    }))}
                                                                    filteringType="auto"
                                                                    placeholder="Select node/store"
                                                                />
                                                            </FormField>
                                                            <FormField label="Metric">
                                                                <Select
                                                                    selectedOption={{label: selectedMetric, value: selectedMetric}}
                                                                    onChange={({detail}) => {
                                                                        setSelectedMetric(detail.selectedOption.value);
                                                                    }}
                                                                    options={metrics.map((item) => ({
                                                                        label: item,
                                                                        value: item,
                                                                    }))}
                                                                    filteringType="auto"
                                                                    placeholder="Select metric"
                                                                />
                                                            </FormField>
                                                        </SpaceBetween>
                                                    </Container>
                                                </Form>
                                            </div>
                                            <div></div>
                                        </ColumnLayout>
                                        <ColumnLayout columns={2}>
                                            {getExplainabilityResponse?.images
                                                ?.filter((image) => image.name.includes('Waterfall'))
                                                .map((image, index) => (
                                                    <div key={index}>
                                                        <Container
                                                            header={
                                                                <Header variant="h3" description="">
                                                                    {image.name}
                                                                </Header>
                                                            }
                                                        >
                                                            <img style={{width: '100%'}} src={image.imageUrl} />
                                                        </Container>
                                                    </div>
                                                ))}
                                        </ColumnLayout>
                                    </SpaceBetween>
                                ),
                            },
                        ]}
                    />
                )}
            </Container>
        </React.Fragment>
    );
}

export default memo(LgbmExplainer);
