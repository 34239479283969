import {AnalyticsAPIforExcelsiorAnalytics} from '@amzn/f3-excelsior-analytics-api';
import {useExecute} from './use-async';
import {DependencyList, useCallback, useContext} from 'react';
import {wrapOnErrorListener} from '../common';
import {ForecastContext} from '../forecast-builder';

export function useRetrieveInsights(
    clientConfiguration: AnalyticsAPIforExcelsiorAnalytics.Types.ClientConfiguration,
    onErrorListener: (e: any) => void,
    deps: DependencyList,
    client = new AnalyticsAPIforExcelsiorAnalytics(clientConfiguration)
) {
    return useExecute<
        AnalyticsAPIforExcelsiorAnalytics.Types.GetExplainabilityResponse,
        (
            r: AnalyticsAPIforExcelsiorAnalytics.Types.GetExplainabilityRequest
        ) => Promise<AnalyticsAPIforExcelsiorAnalytics.Types.GetExplainabilityResponse>
    >(
        useCallback(
            (request: AnalyticsAPIforExcelsiorAnalytics.Types.GetExplainabilityRequest) =>
                wrapOnErrorListener(client.getExplainability(request).promise(), onErrorListener),
            deps
        )
    );
}

export function useRetrieveAggregatedRecords(
    clientConfiguration: AnalyticsAPIforExcelsiorAnalytics.Types.ClientConfiguration,
    onErrorListener: (e: any) => void,
    deps: DependencyList,
    client = new AnalyticsAPIforExcelsiorAnalytics(clientConfiguration)
) {
    const {aggregatedData, setAggregatedData} = useContext(ForecastContext);

    return useExecute<
        AnalyticsAPIforExcelsiorAnalytics.Types.GetAggregatedRecordsOutput,
        (
            r: AnalyticsAPIforExcelsiorAnalytics.Types.GetAggregatedRecordsInput
        ) => Promise<AnalyticsAPIforExcelsiorAnalytics.Types.GetAggregatedRecordsOutput>
    >(
        useCallback(async (request: AnalyticsAPIforExcelsiorAnalytics.Types.GetAggregatedRecordsInput) => {
            try {
                const cacheKey = JSON.stringify(request);
                if (aggregatedData && aggregatedData[cacheKey]) {
                    return Promise.resolve(aggregatedData[cacheKey]);
                }
                const response = await client.getAggregatedRecords(request).promise();
                if (!aggregatedData) {
                    setAggregatedData({[cacheKey]: response});
                } else {
                    aggregatedData[cacheKey] = response;
                    setAggregatedData(aggregatedData);
                }
                return wrapOnErrorListener(Promise.resolve(response), onErrorListener);
            } catch (error) {
                return wrapOnErrorListener(Promise.reject(), onErrorListener);
            }
        }, deps)
    );
}

export function useRetrieveForecast(
    clientConfiguration: AnalyticsAPIforExcelsiorAnalytics.Types.ClientConfiguration,
    onErrorListener: (e: any) => void,
    deps: DependencyList,
    client = new AnalyticsAPIforExcelsiorAnalytics(clientConfiguration)
) {
    const {forecast, setForecast} = useContext(ForecastContext);

    return useExecute<
        AnalyticsAPIforExcelsiorAnalytics.Types.GetForecastOutput,
        (
            r: AnalyticsAPIforExcelsiorAnalytics.Types.GetForecastInput
        ) => Promise<AnalyticsAPIforExcelsiorAnalytics.Types.GetForecastOutput>
    >(
        useCallback(async (request: AnalyticsAPIforExcelsiorAnalytics.Types.GetForecastInput) => {
            try {
                const cacheKey = JSON.stringify(request);
                if (forecast && forecast[cacheKey]) {
                    return Promise.resolve(forecast[cacheKey]);
                }
                const response = await client.getForecast(request).promise();
                if (!forecast) {
                    setForecast({[cacheKey]: response});
                } else {
                    forecast[cacheKey] = response;
                    setForecast(forecast);
                }
                return wrapOnErrorListener(Promise.resolve(response), onErrorListener);
            } catch (error) {
                return wrapOnErrorListener(Promise.reject(), onErrorListener);
            }
        }, deps)
    );
}

export function useRetrieveBaselineWeeks(
    clientConfiguration: AnalyticsAPIforExcelsiorAnalytics.Types.ClientConfiguration,
    onErrorListener: (e: any) => void,
    deps: DependencyList,
    client = new AnalyticsAPIforExcelsiorAnalytics(clientConfiguration)
) {
    return useExecute<
        AnalyticsAPIforExcelsiorAnalytics.Types.GetBaselineWeeksOutput,
        (
            r: AnalyticsAPIforExcelsiorAnalytics.Types.GetBaselineWeeksInput
        ) => Promise<AnalyticsAPIforExcelsiorAnalytics.Types.GetBaselineWeeksOutput>
    >(
        useCallback(
            (request: AnalyticsAPIforExcelsiorAnalytics.Types.GetBaselineWeeksInput) =>
                wrapOnErrorListener(client.getBaselineWeeks(request).promise(), onErrorListener),
            deps
        )
    );
}

export function useGetExplainableDataObjects(
    clientConfiguration: AnalyticsAPIforExcelsiorAnalytics.Types.ClientConfiguration,
    onErrorListener: (e: any) => void,
    deps: DependencyList,
    client = new AnalyticsAPIforExcelsiorAnalytics(clientConfiguration)
) {
    return useExecute<
        AnalyticsAPIforExcelsiorAnalytics.Types.GetExplainabilityV2Output,
        (
            r: AnalyticsAPIforExcelsiorAnalytics.Types.GetExplainabilityV2Input
        ) => Promise<AnalyticsAPIforExcelsiorAnalytics.Types.GetExplainabilityV2Output>
    >(
        useCallback(
            (request: AnalyticsAPIforExcelsiorAnalytics.Types.GetExplainabilityV2Input) =>
                wrapOnErrorListener(client.getExplainabilityV2(request).promise(), onErrorListener),
            deps
        )
    );
}

export function useGetMostRecentModelRunMetadata(
    clientConfiguration: AnalyticsAPIforExcelsiorAnalytics.Types.ClientConfiguration,
    onErrorListener: (e: any) => void,
    deps: DependencyList,
    client = new AnalyticsAPIforExcelsiorAnalytics(clientConfiguration)
) {
    return useExecute<
        AnalyticsAPIforExcelsiorAnalytics.Types.GetMostRecentModelRunMetadataOutput,
        (
            r: AnalyticsAPIforExcelsiorAnalytics.Types.GetMostRecentModelRunMetadataInput
        ) => Promise<AnalyticsAPIforExcelsiorAnalytics.Types.GetMostRecentModelRunMetadataOutput>
    >(
        useCallback(
            (request: AnalyticsAPIforExcelsiorAnalytics.Types.GetMostRecentModelRunMetadataInput) =>
                wrapOnErrorListener(client.getMostRecentModelRunMetadata(request).promise(), onErrorListener),
            deps
        )
    );
}
