import React, {useState} from 'react';
import {Box, Button, Header, Modal, SpaceBetween, Table} from '@amzn/awsui-components-react';
import {ServiceResource, useAuth} from '../hooks/use-auth';
import {useDeleteEvents, useListUpcomingEvents, usePause, useStart, useStop} from '../hooks/use-orchestrator-api';
import {Orchestrator} from '@amzn/f3-excelsior-orchestrator-api';
import RouterButton from '../navigation/router-button';
import Timestamp from '../common/timestamp';
import {Notification} from '../navigation/page-layout';
import {translateErrorToReactNode} from '../common';
import {ViewScheduleEvent} from './view-schedule-event';
import {RescheduleEventModal} from './reschedule-event-modal';

export default function ViewOperationalSchedule(props: {pushNotification: (notification: Notification) => void}) {
    const auth = useAuth();
    const [selectedItems, setSelectedItems] = useState([] as Orchestrator.Types.ConcreteEvents);
    const [scheduleEventDetailVisible, setScheduleEventDetailVisible] = useState(false);
    const [rescheduleModalVisible, setRescheduleModalVisible] = useState(false);

    const orchestratorViewClientConfiguration = auth.authInformation!.getCurrentServiceEndpoint(ServiceResource.OrchestratorView);
    const orchestratorEditClientConfiguration = auth.authInformation!.getCurrentServiceEndpoint(ServiceResource.OrchestratorEdit);

    const businessApiContext = {
        businessId: auth.authInformation!.current!.businessId,
        country: auth.authInformation!.current!.country,
        flow: auth.authInformation!.current!.flow,
    };

    function createErrorListener<T>(header: string) {
        return (e: any) => {
            props.pushNotification({
                type: 'error',
                content: translateErrorToReactNode(e),
                header,
            });
        };
    }

    const listUpcomingEventsErrorListener = createErrorListener('ListUpcomingEvents failed');
    const {
        execute: executeListUpcomingEvents,
        status: executeListUpcomingEventsStatus,
        value: listUpcomingEventsResponse,
    } = useListUpcomingEvents(orchestratorViewClientConfiguration, businessApiContext, true, listUpcomingEventsErrorListener, [
        auth,
    ]);

    const startErrorListener = createErrorListener('Start failed');
    const {execute: executeStart, status: executeStartStatus} = useStart(
        orchestratorEditClientConfiguration,
        businessApiContext,
        false,
        startErrorListener,
        [auth]
    );

    const stopErrorListener = createErrorListener('Stop failed');
    const {execute: executeStop, status: executeStopStatus} = useStop(
        orchestratorEditClientConfiguration,
        businessApiContext,
        false,
        stopErrorListener,
        [auth]
    );

    const pauseErrorListener = createErrorListener('Pause failed');
    const {execute: executePause, status: executePauseStatus} = usePause(
        orchestratorEditClientConfiguration,
        businessApiContext,
        false,
        pauseErrorListener,
        [auth]
    );

    const deleteEventRequest: Orchestrator.Types.DeleteEventsRequest = {
        ...businessApiContext,
        eventIds: selectedItems.map((concreteEvent) => concreteEvent.eventId),
    };
    const deleteEventsListener = createErrorListener('DeleteEvents failed');
    const {execute: executeDeleteEvents, status: executeDeleteEventsStatus} = useDeleteEvents(
        orchestratorEditClientConfiguration,
        deleteEventRequest,
        false,
        deleteEventsListener,
        [auth, selectedItems]
    );

    const isLoading = executeListUpcomingEventsStatus === 'idle' || executeListUpcomingEventsStatus === 'pending';
    const allPendingStatus = [
        executeListUpcomingEventsStatus === 'pending',
        executeStartStatus === 'pending',
        executeStopStatus === 'pending',
        executePauseStatus === 'pending',
        executeDeleteEventsStatus === 'pending',
    ];
    const isTherePendingStatus = allPendingStatus.reduce((left, right) => left || right);
    const singleItemOperationDisabled = selectedItems.length === 0 || isTherePendingStatus;
    const buttonVariant = singleItemOperationDisabled ? 'normal' : 'primary';

    const columnDefinitions = [
        {
            id: 'event_id',
            header: 'Event Id',
            cell: (e: Orchestrator.Types.ConcreteEvent) => e.eventId,
        },
        {
            id: 'schedule_time',
            header: 'Scheduled Time',
            cell: (e: Orchestrator.Types.ConcreteEvent) => <Timestamp datetime={e.scheduledTime} />,
        },
        {
            id: 'type',
            header: 'type',
            cell: (e: Orchestrator.Types.ConcreteEvent) => getType(e),
        },
        {
            id: 'status',
            header: 'Status',
            cell: (e: Orchestrator.Types.ConcreteEvent) => e.status,
        },
        {
            id: 'original_schedule_time',
            header: 'Original Scheduled Time',
            cell: (e: Orchestrator.Types.ConcreteEvent) => <Timestamp datetime={e.originalScheduledTime} />,
        },
        {
            id: 'sla',
            header: 'Sla',
            cell: (e: Orchestrator.Types.ConcreteEvent) => getDuration(e.sla),
        },
    ];

    return (
        <React.Fragment>
            <div className="main-header">
                <Header variant="h1" description="View and/or modify the schedule">
                    Operational Schedule
                </Header>
            </div>
            <div className="main-content">
                <Table
                    data-testid="operational-schedule"
                    columnDefinitions={columnDefinitions}
                    onSelectionChange={({detail}) => setSelectedItems(detail.selectedItems)}
                    selectedItems={selectedItems}
                    items={listUpcomingEventsResponse?.events || []}
                    loading={isLoading}
                    loadingText="Loading events"
                    resizableColumns
                    selectionType="single"
                    sortingDisabled
                    visibleColumns={['schedule_time', 'original_schedule_time', 'status', 'sla', 'type']}
                    trackBy={(e: Orchestrator.Types.ConcreteEvent) => e.eventId}
                    empty={
                        <Box textAlign="center" color="inherit">
                            <b>No events</b>
                            <Box padding={{bottom: 's'}} variant="p" color="inherit">
                                No events to display.
                            </Box>
                        </Box>
                    }
                    header={
                        <Header
                            actions={
                                <SpaceBetween size="xs" direction="horizontal">
                                    <Button
                                        data-testid="refresh-button"
                                        iconName="refresh"
                                        variant="normal"
                                        disabled={isTherePendingStatus}
                                        onClick={() => {
                                            // wipe out any item selected
                                            setSelectedItems([]);
                                            executeListUpcomingEvents();
                                        }}
                                    />
                                    <Button
                                        data-testid="view-event-button"
                                        variant={buttonVariant}
                                        disabled={singleItemOperationDisabled}
                                        onClick={() => setScheduleEventDetailVisible(true)}
                                    >
                                        View Event Details
                                    </Button>
                                    <Button
                                        data-testid="delete-event-button"
                                        variant={buttonVariant}
                                        disabled={singleItemOperationDisabled}
                                        onClick={async () => {
                                            await executeDeleteEvents();
                                            setSelectedItems([]);
                                            executeListUpcomingEvents();
                                        }}
                                    >
                                        Delete Event
                                    </Button>
                                    <Button
                                        data-testid="reschedule-event-button"
                                        variant={buttonVariant}
                                        disabled={singleItemOperationDisabled}
                                        onClick={() => setRescheduleModalVisible(true)}
                                    >
                                        Reschedule Event
                                    </Button>
                                    <Button
                                        data-testid="start-button"
                                        variant="primary"
                                        disabled={isTherePendingStatus}
                                        onClick={async () => {
                                            setSelectedItems([]);
                                            await executeStart();
                                            executeListUpcomingEvents();
                                        }}
                                    >
                                        Start
                                    </Button>
                                    <Button
                                        data-testid="stop-button"
                                        variant="primary"
                                        disabled={isTherePendingStatus}
                                        onClick={async () => {
                                            setSelectedItems([]);
                                            await executeStop();
                                            executeListUpcomingEvents();
                                        }}
                                    >
                                        Stop
                                    </Button>
                                    <Button
                                        data-testid="pause-button"
                                        variant="primary"
                                        disabled={isTherePendingStatus}
                                        onClick={async () => {
                                            setSelectedItems([]);
                                            await executePause();
                                            executeListUpcomingEvents();
                                        }}
                                    >
                                        Pause
                                    </Button>
                                    <RouterButton data-testid="view-schedule-button" variant="primary" href={'/schedule'}>
                                        View Schedule
                                    </RouterButton>
                                </SpaceBetween>
                            }
                        >
                            Upcoming Events{' '}
                            {listUpcomingEventsResponse?.status ? '(' + listUpcomingEventsResponse?.status + ')' : ''}
                        </Header>
                    }
                />
            </div>
            <Modal
                onDismiss={() => setScheduleEventDetailVisible(false)}
                visible={scheduleEventDetailVisible}
                size="medium"
                header={'Schedule Event Detail'}
            >
                <ViewScheduleEvent event={selectedItems[0] || {}} />
            </Modal>
            {!singleItemOperationDisabled && (
                <RescheduleEventModal
                    visible={rescheduleModalVisible}
                    onCancel={() => setRescheduleModalVisible(false)}
                    onConfirm={() => {
                        setRescheduleModalVisible(false);
                        executeListUpcomingEvents();
                    }}
                    pushNotification={props.pushNotification}
                    event={selectedItems[0]}
                />
            )}
        </React.Fragment>
    );
}

function getDuration(duration?: Orchestrator.Types.Duration): string {
    let message = '';

    if (duration?.hour) {
        message += duration.hour + ' hour' + (duration.hour > 1 ? 's ' : ' ');
    }

    if (duration?.minute) {
        message += duration.minute + ' minute' + (duration.minute > 1 ? 's ' : ' ');
    }

    if (duration?.second) {
        message += duration.second + ' second' + (duration.second > 1 ? 's ' : ' ');
    }

    return message;
}

function getType(event: Orchestrator.Types.ConcreteEvent): string {
    if (event.generator) {
        return 'generator';
    }

    if (event.ingestion) {
        return 'ingestion';
    }

    if (event.publish) {
        return 'publish';
    }

    if (event.promote) {
        return 'promote';
    }

    if (event.calculateAccuracy) {
        return 'calculate_accuracy';
    }

    if (event.percentileForecast) {
        return 'percentile_forecast';
    }

    return 'unknown';
}
