import React from 'react';
import {Header, Link} from '@amzn/awsui-components-react';

export default function Resources() {
    return (
        <div className={'mainArea'}>
            <Header variant="h1">Resources</Header>
            <Link
                href="https://w.amazon.com/bin/view/F3/F3_Grocery_Supply_Chain/Sales_And_Operations_Planning/Team_GROOT"
                target={'_blank'}
                rel={'noopener noreferrer'}
                fontSize="heading-m"
            >
                Team Wiki
            </Link>
        </div>
    );
}
