import React from 'react';
import {Notification} from '../navigation/page-layout';
import {HiFoExplainer} from './hifo-explainer';
import {useAuth} from '../hooks/use-auth';
import {LgbmExplainer} from './lgbm-explainer';
import {Header} from '@amzn/awsui-components-react';

export default function ViewInsights(props: {pushNotification: (notification: Notification) => void}) {
    const auth = useAuth();
    const configurationData = auth.authInformation!.current!.configurationData!;

    const componenyByModelName = {
        hifo: <HiFoExplainer pushNotification={props.pushNotification}></HiFoExplainer>,
        lgbm: <LgbmExplainer pushNotification={props.pushNotification}></LgbmExplainer>,
    };

    if (!configurationData.modelName) {
        return <React.Fragment></React.Fragment>;
    }

    return (
        <React.Fragment>
            <div className="main-content">
                <Header variant="h1">{`Insights - ${configurationData.modelName.toUpperCase()}`}</Header>
                {componenyByModelName[configurationData.modelName]}
            </div>
        </React.Fragment>
    );
}
