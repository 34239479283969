import React, {memo, useEffect, useRef, useState} from 'react';
import {Notification} from '../navigation/page-layout';
import {useRouter} from '../hooks/use-router';
import {ServiceResource, useAuth} from '../hooks/use-auth';
import {translateErrorToReactNode} from '../common';
import {useRetrieveInsights} from '../hooks/use-analytics-api';
import {
    Box,
    Button,
    ColumnLayout,
    Container,
    Header,
    LineChart,
    MixedLineBarChartProps,
    Multiselect,
    Pagination,
    SpaceBetween,
    Table,
} from '@amzn/awsui-components-react';
import {AnalyticsAPIforExcelsiorAnalytics} from '@amzn/f3-excelsior-analytics-api';
import {recordUserEvent} from '../common/portal-analytics';
import {PortalEventName} from '../common/portal-event-name-enum';

type LineSeries = MixedLineBarChartProps.LineDataSeries<string>;

const FormattedFloat = ({value}) => {
    const formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 5,
        maximumFractionDigits: 5,
    });

    return <span>{formatter.format(value)}</span>;
};

const FormattedInteger = ({value}) => {
    const formatter = new Intl.NumberFormat('en-US', {
        maximumFractionDigits: 0,
    });

    return <span>{formatter.format(value)}</span>;
};

function getPageItems<T>(items: T[], currentPageIndex: number, itemsPerPage: number): T[] {
    // Adjust the page index to be zero-based
    const zeroBasedPageIndex = currentPageIndex - 1;

    // Calculate the start and end indices for the current page
    const startIndex = zeroBasedPageIndex * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    // Use array slicing to get the items for the current page
    const pageItems = items.slice(startIndex, endIndex);

    return pageItems;
}

function getLastFourWeeksAverage(data: LineSeries[]): any[] {
    const averages: {node: string; avg: number}[] = data.map((nodeData) => {
        const lastFourEntries = nodeData.data.slice(-4);
        const sum = lastFourEntries.reduce((acc, curr) => acc + curr.y, 0);
        const avg = sum / lastFourEntries.length;
        return {node: nodeData.title, avg};
    });
    return averages;
}

export function HiFoExplainer(props: {pushNotification: (notification: Notification) => void}) {
    const auth = useAuth();
    const router = useRouter();
    const initialized = useRef(false);
    const isLoading = useRef<boolean>(true);

    const [series, setSeries] = useState<LineSeries[][]>([]);
    const [visibleSeries, setVisibleSeries] = useState<string[][]>([]);
    const [topFiveNodes, setTopFiveNodes] = useState<string[][]>([]);
    const [lowFiveNodes, setLowFiveNodes] = useState<string[][]>([]);
    const [nodes, setNodes] = useState<string[]>([]);
    const [selectedNodes, setSelectedNodes] = useState<string[]>([]);

    const [insightRecords, setInsightRecords] = useState<AnalyticsAPIforExcelsiorAnalytics.Types.Records>([]);
    const [weeks, setWeeks] = useState<string[]>([]);
    const [currentPageIndex, setCurrentPageIndex] = useState<number>(1);
    const [pagesCount, setPagesCount] = useState<number>(1);
    const itemsPerPage = 5;
    const weeksToDisplayInTable = 10;

    /**
     * TODO: insightValueTypes will come from the configuration store in the future
     */
    const insightValueTypesForChart = [
        {
            id: 'global_outbound_actuals_coefficient',
            friendlyName: 'Global Coefficient',
            description: `A coefficient describes the relationship between Outbound volume and Inbound forecast. 
                A higher coefficient means a stronger relationship. Global coefficient is used to infer the forecast.
                The closer the global coefficient is to 0, the more the forecast will be driven by a 12-week moving average. 
                The closer it is to 1, the more the forecast will be driven by Outbound volume.`,
            viewType: 'chart',
        },
        {
            id: 'local_outbound_actuals_coefficient',
            friendlyName: 'Local Coefficient',
            description:
                'A coefficient describes the relationship between Outbound volume and Inbound forecast. A higher coefficient means a stronger relationship.',
            viewType: 'chart',
        },
    ];
    const allValueTypes = [
        ...[{id: 'baselineForecast', friendlyName: 'Baseline Forecast', viewType: 'table'}],
        ...insightValueTypesForChart,
    ];
    const clientConfiguration = auth.authInformation!.getCurrentServiceEndpoint(ServiceResource.ExplainabilityView);

    const modelName = auth.authInformation?.current?.configurationData?.modelName ?? '';

    function createErrorListener<T>(header: string) {
        return (e: any) => {
            props.pushNotification({
                type: 'error',
                content: translateErrorToReactNode(e),
                header,
            });
        };
    }

    const {
        execute: excuteRetrieveInsights,
        status: status,
        value: getExplainabilityResponse,
    } = useRetrieveInsights(clientConfiguration, createErrorListener('RetrieveInsights failed'), [auth]);

    isLoading.current = status === 'idle' || status === 'pending';
    if (!initialized.current) {
        const tenant = {
            business: auth.authInformation!.current!.businessId,
            country: auth.authInformation!.current!.country,
            flow: auth.authInformation!.current!.flow,
        };

        recordUserEvent({
            eventData: {
                ...tenant,
                email: auth.authInformation!.email,
                routerPathName: router.pathname,
                pageName: 'Model Explainability',
                loginTimeStamp: new Date().toLocaleString(),
            },
            eventName: PortalEventName.pageVisited,
        });

        excuteRetrieveInsights({
            ...tenant,
            forecastType: 'sop',
            filter: JSON.stringify({modelName: modelName}),
        });
        initialized.current = true;
    }

    useEffect(() => {
        if (!isLoading.current && getExplainabilityResponse?.records && getExplainabilityResponse.records.length > 0) {
            const recordsSorted = getExplainabilityResponse.records.sort((a, b) => a.node.localeCompare(b.node));
            const lineSeriesCollection: LineSeries[][] = insightValueTypesForChart.map((valueType) =>
                recordsSorted.map((record) => ({
                    title: record.node,
                    type: 'line',
                    data: Object.entries(record.insightValuesByTime).map(([balanceWeek, data]) => ({
                        x: balanceWeek,
                        y: data[valueType.id],
                    })),
                }))
            );

            const visibleSeriesCollection = lineSeriesCollection.map((lineSeries) =>
                lineSeries.map((item) => item.title).slice(0, 5)
            );
            setSeries(lineSeriesCollection);
            setVisibleSeries(visibleSeriesCollection);

            const tableRecords = recordsSorted.map((record) => {
                const {node, insightValuesByTime} = record;
                const valuesByTimeEntries = Object.entries(insightValuesByTime);
                const lastTwoEntries = valuesByTimeEntries.slice(-weeksToDisplayInTable);
                const filteredValuesByTime = Object.fromEntries(lastTwoEntries);

                return {
                    node,
                    insightValuesByTime: filteredValuesByTime,
                };
            });
            setInsightRecords(tableRecords);
            setNodes(recordsSorted.map((record) => record.node));
            setSelectedNodes(recordsSorted.map((record) => record.node));
            setTopFiveNodes(
                lineSeriesCollection.map((lineSeries) =>
                    getLastFourWeeksAverage(lineSeries)
                        .sort((a, b) => b.avg - a.avg)
                        .slice(0, 5)
                        .map((item) => item.node)
                )
            );
            setLowFiveNodes(
                lineSeriesCollection.map((lineSeries) =>
                    getLastFourWeeksAverage(lineSeries)
                        .sort((a, b) => a.avg - b.avg)
                        .slice(0, 5)
                        .map((item) => item.node)
                )
            );

            /**
             * This is very primitive for now. We can get the min and max week returned from the API later.
             */
            setWeeks(Object.keys(recordsSorted[0].insightValuesByTime).slice(-weeksToDisplayInTable));
            setPagesCount(Math.floor(recordsSorted.length / itemsPerPage) + 1);
        }
    }, [isLoading.current]);

    return (
        <React.Fragment>
            <SpaceBetween size="s">
                <Container
                    header={
                        <Header
                            variant="h2"
                            description="Historical view of weekly T+0 baseline forecast alongside local and global coefficients; Each date in the table columns represents a balance week."
                            counter={'(' + selectedNodes.length + ')'}
                        >
                            Weekly View of Baseline Forecast with Coefficients
                        </Header>
                    }
                >
                    <SpaceBetween size="s">
                        <ColumnLayout columns={2}>
                            <div>
                                <Multiselect
                                    selectedOptions={selectedNodes.map((item) => ({
                                        label: item,
                                        value: item,
                                    }))}
                                    onChange={({detail}) => {
                                        const count = Math.floor(detail.selectedOptions.length / itemsPerPage) + 1;
                                        setPagesCount(count);
                                        setSelectedNodes(detail.selectedOptions.map((item) => item.value!));
                                    }}
                                    options={nodes.map((item) => ({
                                        label: item,
                                        value: item,
                                    }))}
                                    tokenLimit={5}
                                    filteringType="auto"
                                    placeholder="Select nodes/stores"
                                />
                            </div>
                            <div>
                                <ColumnLayout columns={2}>
                                    <div>
                                        <SpaceBetween direction="horizontal" size="xs">
                                            <Button
                                                onClick={(detail) => {
                                                    setCurrentPageIndex(1);
                                                    const count = Math.floor(nodes.length / itemsPerPage) + 1;
                                                    setPagesCount(count);
                                                    setSelectedNodes(nodes);
                                                }}
                                            >
                                                Select All
                                            </Button>
                                            <Button
                                                onClick={(detail) => {
                                                    setCurrentPageIndex(1);
                                                    setPagesCount(1);
                                                    setSelectedNodes([]);
                                                }}
                                            >
                                                De-select All
                                            </Button>
                                        </SpaceBetween>
                                    </div>
                                </ColumnLayout>
                            </div>
                        </ColumnLayout>
                        <Table
                            stickyHeader
                            stripedRows
                            pagination={
                                <Pagination
                                    currentPageIndex={currentPageIndex}
                                    pagesCount={pagesCount}
                                    onChange={({detail}) => {
                                        setCurrentPageIndex(detail.currentPageIndex);
                                    }}
                                />
                            }
                            columnDefinitions={[
                                ...[
                                    {
                                        id: 'node',
                                        header: 'Node',
                                        cell: (item: AnalyticsAPIforExcelsiorAnalytics.Types.ExplainabilityRecord) => item.node,
                                        isRowHeader: true,
                                    },
                                    {
                                        id: 'valueType',
                                        header: 'Value Type',
                                        cell: (item: any) =>
                                            allValueTypes.map((valueType) => (
                                                <React.Fragment key={valueType.id}>
                                                    <div>{valueType.friendlyName}</div>
                                                </React.Fragment>
                                            )),
                                        isRowHeader: true,
                                    },
                                ],
                                ...weeks.map((week) => ({
                                    id: week,
                                    header: week,
                                    isRowHeader: true,
                                    cell: (item: AnalyticsAPIforExcelsiorAnalytics.Types.ExplainabilityRecord) => (
                                        <React.Fragment>
                                            <div>
                                                {<FormattedInteger value={item.insightValuesByTime[week]?.baselineValue ?? 0} />}
                                            </div>
                                            {insightValueTypesForChart.map((insightValueType) => (
                                                <React.Fragment key={insightValueType.id}>
                                                    <div>
                                                        {
                                                            <FormattedFloat
                                                                value={item.insightValuesByTime[week]?.[insightValueType.id] ?? 0}
                                                            />
                                                        }
                                                    </div>
                                                </React.Fragment>
                                            ))}
                                        </React.Fragment>
                                    ),
                                })),
                            ]}
                            items={getPageItems(
                                insightRecords.filter((item) => selectedNodes.includes(item.node)),
                                currentPageIndex,
                                itemsPerPage
                            )}
                            empty={
                                <Box textAlign="center" color="inherit">
                                    <b>No matching data</b>
                                    <Box variant="p" color="inherit">
                                        There is no matching data to display
                                    </Box>
                                </Box>
                            }
                        ></Table>
                    </SpaceBetween>
                </Container>

                {series.map((s, index) => (
                    <React.Fragment key={insightValueTypesForChart[index].id}>
                        <Container
                            header={
                                <Header variant="h2" description={insightValueTypesForChart[index].description}>
                                    {insightValueTypesForChart[index].friendlyName}
                                </Header>
                            }
                        >
                            <SpaceBetween size="s">
                                <ColumnLayout columns={2}>
                                    <div>
                                        <Multiselect
                                            selectedOptions={visibleSeries[index].map((item) => ({
                                                label: item,
                                                value: item,
                                            }))}
                                            onChange={({detail}) => {
                                                const ids = detail.selectedOptions.map((option) => option.value!);
                                                const tempVisibleSeries = [...visibleSeries];
                                                tempVisibleSeries[index] = nodes.filter((node) => ids.includes(node));
                                                setVisibleSeries([...tempVisibleSeries]);
                                            }}
                                            options={nodes.map((item) => ({
                                                label: item,
                                                value: item,
                                            }))}
                                            tokenLimit={5}
                                            filteringType="auto"
                                            placeholder="Select nodes/stores"
                                        />
                                    </div>
                                    <div>
                                        <SpaceBetween direction="horizontal" size="xs">
                                            <Button
                                                onClick={(detail) => {
                                                    const tempVisibleSeries = [...visibleSeries];
                                                    tempVisibleSeries[index] = topFiveNodes[index];
                                                    setVisibleSeries([...tempVisibleSeries]);
                                                }}
                                            >
                                                Top 5
                                            </Button>
                                            <Button
                                                onClick={(detail) => {
                                                    const tempVisibleSeries = [...visibleSeries];
                                                    tempVisibleSeries[index] = lowFiveNodes[index];
                                                    setVisibleSeries([...tempVisibleSeries]);
                                                }}
                                            >
                                                Low 5
                                            </Button>
                                            <Button
                                                onClick={(detail) => {
                                                    const tempVisibleSeries = [...visibleSeries];
                                                    tempVisibleSeries[index] = nodes;
                                                    setVisibleSeries([...tempVisibleSeries]);
                                                }}
                                            >
                                                Select All
                                            </Button>
                                            <Button
                                                onClick={(detail) => {
                                                    const tempVisibleSeries = [...visibleSeries];
                                                    tempVisibleSeries[index] = [];
                                                    setVisibleSeries([...tempVisibleSeries]);
                                                }}
                                            >
                                                De-select All
                                            </Button>
                                        </SpaceBetween>
                                    </div>
                                </ColumnLayout>
                                <LineChart
                                    series={s}
                                    data-testid={insightValueTypesForChart[index].id}
                                    key={insightValueTypesForChart[index].id}
                                    visibleSeries={s.filter((item) => visibleSeries[index].includes(item.title))}
                                    yDomain={[0, 1]}
                                    ariaLabel="Single data series line chart"
                                    height={300}
                                    loadingText="Loading data..."
                                    hideLegend
                                    hideFilter
                                    xScaleType="categorical"
                                    xTitle="Balance Week (T+0)"
                                    yTitle={insightValueTypesForChart[index].id}
                                    empty={
                                        <Box textAlign="center" color="inherit">
                                            <b>No data available</b>
                                            <Box variant="p" color="inherit">
                                                There is no data available
                                            </Box>
                                        </Box>
                                    }
                                    noMatch={
                                        <Box textAlign="center" color="inherit">
                                            <b>No matching data</b>
                                            <Box variant="p" color="inherit">
                                                There is no matching data to display
                                            </Box>
                                        </Box>
                                    }
                                    i18nStrings={{filterPlaceholder: 'Select Node'}}
                                />
                            </SpaceBetween>
                        </Container>
                    </React.Fragment>
                ))}
            </SpaceBetween>
        </React.Fragment>
    );
}

export default memo(HiFoExplainer);
